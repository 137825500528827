/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Column from '@amzn/meridian/column';
import { CoreFormFooter, useToaster } from '@amzn/dots-core-ui';
import Input from '@amzn/meridian/input';
import { Host, useClient, useCreate } from '$/api';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Alert from '@amzn/meridian/alert';
import { GroupSelect } from '$/components/GroupSelect';

export type HostFormProps = {
  host?: Host;
  hostname?: string;
  rsapub?: string;
  version?: string;
  onSubmit: (host: Partial<Host>) => void;
  onCancel: () => void;
};

export function HostForm({ onCancel }: HostFormProps): JSX.Element {
  const { openToast } = useToaster();
  const history = useHistory();
  const { hosts } = useClient();
  const { mutateAsync } = useCreate(hosts.resourceKey, hosts.create);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [responseError, setResponseError] = useState('');

  const [ownerFirstName, setOwnerFirstName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');

  useEffect(() => {
    Object.entries(window.localStorage).forEach((entry) => {
      const key = entry[0];
      const value = entry[1];

      const re = /^oidc\.user:https:\/\/adfs\.lassostg\.labcollab.net/;
      if (re.test(key)) {
        const userProfile = JSON.parse(value);
        setOwnerFirstName(userProfile?.profile?.family_name || '');
        setOwnerLastName(userProfile?.profile?.given_name || '');
      }
    });
  }, []);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      ownerLdap: '',
      email: '',
      userArn: '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit(async (vs) => {
        setSubmitLoading(true);
        try {
          const payload: Record<string, any> = {
            name: `host_${vs.name}`,
            version: 'mvp',
            ownerFirstName,
            ownerLastName,
            email: vs.email,
            userArn: vs.userArn,
          };
          const response = await mutateAsync(payload);
          console.log(response);
          openToast({
            title: 'Success!',
            message: `The host has been registered`,
            type: 'success',
          });
          history.push('/');
        } catch (e: any) {
          setResponseError(e.toString());
        }
        setSubmitLoading(false);
      })}
    >
      <Column spacingInset="300" spacing="400" maxWidth="100%" width={650}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Input
                label="Hostname"
                size="medium"
                {...field}
                error={fieldState.invalid}
              />
              {fieldState.invalid && (
                <Alert type="error" size="small">
                  Hostname is required
                </Alert>
              )}
            </>
          )}
          rules={{ required: true }}
        />

        <Controller
          name="ownerLdap"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <GroupSelect value={field.value} onChange={field.onChange} />
              {fieldState.invalid && (
                <Alert type="error" size="small">
                  Owner is required
                </Alert>
              )}
            </>
          )}
          rules={{ required: true }}
        />

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Input
                label="Team E-mail"
                size="medium"
                {...field}
                error={fieldState.invalid}
              />
              {fieldState.invalid && (
                <Alert type="error" size="small">
                  Team e-mail is required
                </Alert>
              )}
            </>
          )}
          rules={{ required: true }}
        />

        <Controller
          name="userArn"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Input
                label="IAM User ARN"
                size="medium"
                {...field}
                error={fieldState.invalid}
              />
              {fieldState.invalid && (
                <Alert type="error" size="small">
                  IAM User ARN is required
                </Alert>
              )}
            </>
          )}
          rules={{ required: true }}
        />

        {responseError !== '' && (
          <Alert type="error" title="Error!" size="large">
            {responseError}
          </Alert>
        )}

        <CoreFormFooter
          isConfirmDisabled={false}
          isLoading={submitLoading}
          onCancelClick={onCancel}
        />
      </Column>
    </form>
  );
}
